<template>
  <layoutComponent image="img-sac/bg-sac.png" tabColor="#30A4F1" :change-icon="true" xs="400">
    <template #title>
      <v-row class="row banner">
        <v-col class="col-12 col-sm-6 col-md-6 container-title px-0">
          <v-img class="mb-8 logo-sac" aspect-ratio="1" src="../../assets/img-sac/logo-sac.svg" alt="logo sac"/>
          <h1 class="title-one">Estamos aquí</h1>
          <h1 class="title-two">para ayudarte</h1>
        </v-col>
        <v-col class="col-12 col-sm-6 col-md-6 bg-complement px-0">
          <v-img src="../../assets/img-sac/bg-complement.svg" alt="" class="bg-complement"/>
        </v-col>
      </v-row>
    </template>
    <template #body-content>
      <div class="d-flex justify-center p-0 m-0">
        <iframe class="p-0 m-0" :src="url_sac" allowfullscreen></iframe>
      </div>
    </template>
  </layoutComponent>
</template>

<script>
import layoutComponent from '@/components/AppLayoutComponent.vue'
import {mapState} from "vuex";

export default {
  name: 'SAC',

  components: {
    layoutComponent
  },
  computed:{
    ...mapState({
      userInfo: "userInfo",
      token: "token",
    }),

  },
  data() {
    return {
      tokens:null,
      url_sac: null,
    }
  },


  created() {
    if (!sessionStorage.getItem('embedded')) {
      sessionStorage.setItem('embedded', true)
    }

    this.tokens =JSON.stringify({
      dui:this.userInfo.user?.numero_documento,
      email:this.userInfo.user?.email,
      id_token:this.userInfo.user?.ID_token,
      tokens:this.token
    })

    this.url_sac = 'https://opinion.sac.gob.sv/false/'+this.tokens

  }
}

</script>
<style scoped lang="css">

iframe{
  border: none;
  height: 100vh;
  width: 100vw;
}

iframe, body {
  margin: 0 !important;
  padding: 0 !important;
}

::v-deep .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active), .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon, .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn, .theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: #30A4F1 !important;
}

h1 {
  font-family: 'museo-sans', sans-serif;
}

.banner {
  width: 100vw;
}

::v-deep .theme--light {
  background-color: transparent !important;
}

.title-one {
  font-size: 1.5rem;
  font-weight: bold;
  color: #031295;
  margin: 0;
  padding: 0;
}

.title-two {
  font-size: 1rem;
  font-weight: normal;
  color: #031295;
  margin: 0;
  padding: 0;
}

.container-title{
  text-align: center !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 15rem !important;
  margin: auto;
}

.container-bg-complement {
//max-height: 50rem !important;
}

.bg-complement{
//max-height: 40rem;
  aspect-ratio: 4/3;
  max-width: 20rem;
  margin: 0 auto;
}

::v-deep .v-toolbar__image .header-img--title-style{
  top: 1rem !important;
}

.logo-sac {
  max-height: 2.2rem;
  max-width: 8rem;
  margin: .5rem !important;
//transform: scale(0.5);
}

@media (min-width: 600px) {

  .banner {
    width: 80vw;
  }

  .bg-complement {
  //height: 20rem;
    width: 15rem;
    transform: scale(1.2);
  }

  .container-title{
    max-height: 15rem !important;
    margin: auto;
  }

  ::v-deep .v-toolbar__image .header-img--title-style{
    top: 4.5rem !important;
  }
}

@media (min-width: 960px) and (max-width: 1124px) {

  .banner {
    width: 70vw;
  }

  .title-one {
    font-size: 2.5rem;
  }

  .title-two {
    font-size: 1.5rem;
  }

  .container-title{
    max-height: 15rem !important;
  }

  .bg-complement {
    height: 20rem;
    width: 36rem;
    transform: scale(1.2);
  }

  ::v-deep .v-toolbar__image .header-img--title-style{
    top: 8vw !important;

  }
}

@media (min-width: 1125px) and (max-width: 1263px) {
  .banner {
    width: 70vw;
  }

  .title-one {
    font-size: 2.5rem;
  }

  .title-two {
    font-size: 1.5rem;
  }

  .container-title{
    max-height: 20rem !important;
  }

  .bg-complement {
    height: 20rem;
    width: 36rem;
    transform: scale(1.3);
  }

  .logo-sac {
    max-height: 64px;
    max-width: 232px;
    margin-bottom: 1rem;
  }

  ::v-deep .v-toolbar__image .header-img--title-style{
    top: 5rem !important;
  }
}

@media (min-width: 1264px) and  (max-width: 1904px) {

  .banner {
    width: 80vw;
  }

  .title-one {
    font-size: 4.5rem;
    font-weight: bold;
    color: #031295;
    margin: 0;
    padding: 0;
  }

  .title-two {
    font-size: 2.5rem;
    font-weight: normal;
    color: #031295;
    margin: 0;
    padding: 0;
  }

  .container-title{
    text-align: center !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 30rem !important;
    margin: 0 20px 0 0;
  }

  .bg-complement{
    height: 25rem;
    width: 20rem;
    transform: scale(1.2);
  }

  ::v-deep .v-toolbar__image .header-img--title-style{
    top: 10rem !important;
  }

  .logo-sac {
    max-height: 64px;
    max-width: 232px;
    margin-bottom: 2rem;
  }
}

@media (min-width: 1905px) {

  .banner {
    width: 80vw;
  }

  .title-one {
    font-size: 4.5rem;
    font-weight: bold;
    color: #031295;
    margin: 0;
    padding: 0;
  }

  .title-two {
    font-size: 2.5rem;
    font-weight: normal;
    color: #031295;
    margin: 0;
    padding: 0;
  }

  .container-title{
    text-align: center !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 30rem !important;
    margin: 0 20px 0 0;
  }

  .bg-complement{
    height: 25rem;
    width: 20rem;
    transform: scale(1.4);
  }

  ::v-deep .v-toolbar__image .header-img--title-style{
    top: 15rem !important;
  }

  .logo-sac {
    max-height: 64px;
    max-width: 232px;
    margin-bottom: 2rem;
  }

}


</style>